<!-- src/App.vue -->
<template>
  <v-app>
    <v-main>
      <v-container fluid class="pa-0 ma-0">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style scoped>
/* Asegúrate de que el contenedor principal ocupe el 100% del ancho de la pantalla */
html, body, #app {
  height: 100%;
  margin: 0;
  width: 100%;
}

.v-main {
  width: 100%;
}

.v-container {
  width: 100%;
  padding: 0; /* Opcional, elimina padding si deseas que el contenedor ocupe todo el ancho */
  margin: 0; /* Opcional, elimina margin si es necesario */
}
</style>
