// Composables
//import { createRouter, createWebHistory } from "vue-router";
import LocalStorageService from "@/LocalStorageService";
/*import { UserTokenStore } from "@/store/UserTokenStore";

const userTokenStore = UserTokenStore();*/
// src/router/index.js
import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter);

const routes = [
  {
    path: "/shift-board",
    name: "ShiftBoard",
    component: () =>
      import( "@/views/shift_board/ShiftBoard.vue"),
    meta: { requiresAuth: true, requiresPermissions: ["view_shift_boards"] }
  },
  
  {
    path: "/",
    name: "Login",
    component: () =>
      import( "@/views/login.vue"),
  },
 
    ];
  
    const router = new VueRouter({
      mode: 'history',
      base: process.env.BASE_URL,
      routes,
    });
/*
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});*/

router.beforeEach((to, from, next) => {
  //setActivePinia(createPinia());
  //const store = useUserStore();

  // Requiere autenticación
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log("Esta entrando aqui");
    // El usuario no está autenticado

    // const lastRouteName = localStorage.getItem('permissions')

    console.log(LocalStorageService.getItem("authenticateUser"));
    //console.log(localStorage.getItem("isAuthenticated"));
    if (
      LocalStorageService.getItem("authenticateUser") == false ||
      !LocalStorageService.getItem("authenticateUser")
    ) {
      console.log("aqui entra tambien");
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
      return;
    }
  }
  // Requiere permisos
  if (to.matched.some((record) => record.meta.requiresPermissions)) {
    const permissions = to.meta.requiresPermissions;
    // El usuario no tiene los permisos requeridos
    //const userPermissions1 = userTokenStore.permissionsUser;
    
    const userPermissions = LocalStorageService.getItem("permissionsUser");
    //console.log(userPermissions);
    const hasPermissions = permissions.every((permission) =>
      //userPermissions.includes(permission)
      userPermissions.includes(permission)
    );

    if (hasPermissions) {
      next();
    } else {
      next("/forbidden");
      return;
    }
  }

  next();
});

export default router;
